import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '15, 53, 75',
		'primary-dark': '11, 36, 51',
		'accent': '255, 214, 0',
		'accent-plus': '0, 0, 0',
	},
});
